import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import "../styles/login.css";

const Login = () => {
  const [passShow, setPassShow] = useState(false);

  return (
    <>
      <section className="Sub_header">
        <h1>Login</h1>
      </section>
      <section className="form login">
        {/* form-container start */}
        <div className="form-content">
          <h2 className="heading">Login</h2>
          <form action="#">
            {/* Emial filed */}
            <div className="field input-field">
              <input
                type="email"
                placeholder="Email"
                className="input"
                required
              />
            </div>
            {/* Password Filed */}
            <div className="field input-field">
              <input
                type={!passShow ? "password" : "text"}
                placeholder="Password"
                className="password"
                required
              />
              <div onClick={() => setPassShow(!passShow)}>
                <AiFillEye className="eye-icon icon" />
                {!passShow ? (
                  <AiFillEye className="eye-icon icon" />
                ) : (
                  <AiFillEyeInvisible className="eye-icon icon" />
                )}
              </div>
            </div>
            {/* Forgot Password */}
            <div className="form-link">
              <Link to="/forgot-password" className="link">
                Forgot password?
              </Link>
            </div>
            {/* Login Button */}
            <div className="field button-field">
              <button>Login</button>
            </div>
          </form>
        </div>
        {/* form-container close */}
      </section>
    </>
  );
};

export default Login;
