import React from "react";
import "../styles/gallery.css";
import Gallery1 from "../assets/images/gallery1.webp";
import Gallery2 from "../assets/images/gallery2.webp";
import Gallery3 from "../assets/images/gallery3.webp";
import Gallery4 from "../assets/images/gallery4.webp";

const Gallery = () => {
  const data = [
    {
      galleryImg: Gallery1,
    },
    {
      galleryImg: Gallery2,
    },
    {
      galleryImg: Gallery3,
    },
    {
      galleryImg: Gallery4,
    },
  ];
  return (
    <>
      <section className="gallery container section" id="gallery">
        <div className="PageBlock">
          <div className="verticalLine"></div>
          <div className="Clear"></div>
        </div>
        <div className="sec_intro">
          <h2 className="sec_title" data-aos="zoom-in">
            Gallery
          </h2>
          {/* <p data-aos="zoom-in" data-aos-duration="2500">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima,
            quasi!
          </p> */}
        </div>
        <div className="main_content">
          {data.map((item) => {
            return (
              <div
                className="box"
                data-aos="zoom-in-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="3000"
              >
                <img src={item.galleryImg} alt="" />
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Gallery;
