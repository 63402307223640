import React from "react";
import { Link } from "react-router-dom";
import { ImFacebook } from "react-icons/im";
import logo from "../../assets/images/logo.png";
import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="sec_container container grid">
          {/* footer logo */}
          <div className="footer_logo">
            <Link to="/" className="logo flex">
              <h1 className="flex">
                <img src={logo} alt="imagea" />
                Chicago Homes
              </h1>
            </Link>
            {/* footer social links */}
            <div className="socials flex">
              <ImFacebook className="icon" />
              <BsTwitter className="icon" />
              <AiFillInstagram className="icon" />
            </div>
          </div>
          {/* footer usefull link */}
          <div className="footer_links">
            <span className="link_title">USEFULL LINKS</span>
            <Link to="/">
              <li>PRIVACY POLICY</li>
            </Link>
            <Link to="/">
              <li>ROOMS</li>
            </Link>
            <Link to="/">
              <li>ABOUT US</li>
            </Link>
            <Link to="/">
              <li>CONTACT US</li>
            </Link>
          </div>
          {/* footer infromation link */}
          <div className="footer_links">
            <span className="link_title">INFROMATION</span>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/">
              <li>SERVICES</li>
            </Link>
            <Link to="/">
              <li>GALLERY</li>
            </Link>
            <Link to="/">
              <li>TESTIMONIALS</li>
            </Link>
          </div>
          {/* footer contact */}
          <div className="footer_links">
            <span className="link_title">Contact Us</span>
            <span className="phone">+91 8882876687</span>
            <span className="email" ><a href="mailto:chicagohomes.enquiries@gmail.com">chicagohomes.enquiries@gmail.com</a></span>
          </div>
        </div>
        <p className="footer_para">
          &copy; Copyrights 2023 - 2024.Chicago homes. All Rights Reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
