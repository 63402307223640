import React, { useEffect } from "react";
import "../styles/rooms.css";
import Room1 from "../assets/images/room1.webp";
import Room2 from "../assets/images/room2.webp";
import Room3 from "../assets/images/room3.webp";
import Aos from "aos";
import "aos/dist/aos.css";

const data = [
  {
    roomsImgUrl: Room1,
    roomsHeading: "Single bed Room",
  },
  {
    roomsImgUrl: Room2,
    roomsHeading: "Double Sharing- 2 Bed Room",
  },
  {
    roomsImgUrl: Room3,
    roomsHeading: "Triple Sharing- 3 Bed Room",
  },
];

const Rooms = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="rooms container section" id="rooms">
        <div className="PageBlock">
          <div className="verticalLine"></div>
          <div className="Clear"></div>
        </div>
        <div className="sec_intro">
          <h2 className="sec_title" data-aos="zoom-in">
            Rooms
          </h2>
          {/* <p data-aos="zoom-in" data-aos-duration="2500">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima,
            quasi!
          </p> */}
        </div>
        <div className="row">
          {data.map((item) => {
            return (
              <div
                className="rooms_col"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="3000"
              >
                <img src={item.roomsImgUrl} alt="" />
                <h3>{item.roomsHeading}</h3>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Rooms;
