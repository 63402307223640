import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Rooms from "../components/Rooms";
import Services from "../components/Services";
import "../styles/home.css";
import Aos from "aos";
import "aos/dist/aos.css";
import About from "../components/About";
import Gallery from "../components/Gallery";
import Location from "../components/Location";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      {/* Home Section Start */}
      <section className="home" id="#home">
        <div className="sec_container container">
          <div className="home_text">
            <h1 className="title" data-aos="fade-up">
              Simplify Your Room Hunting with Chicago Room
            </h1>
            <p
              className="sub_title"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              Find your perfect room at Chicago Homes, offering safe and
              affordable options across various locations for students,
              professionals, and anyone in need of temporary accommodation.
            </p>
            <Link to="/contact">
              <button
                className="btn"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Enquire Now
              </button>
            </Link>
          </div>
        </div>
      </section>
      {/* Home Section End */}
      <Rooms id="rooms" />
      <Services id="services" />
      <About id="about" />
      <Gallery id="gallery" />
      <Location id="location" />
    </>
  );
};

export default Home;
