import React from "react";
import "../styles/location.css";
import location1 from "../assets/images/gallery1.webp";
import location2 from "../assets/images/gallery2.webp";
import location3 from "../assets/images/gallery3.webp";
import location4 from "../assets/images/gallery4.webp";

const locationData = [
  {
    locationImage: location1,
    locationHeading: "Sector 63 Noida, U.P",
    locationPara: "",
  },
  {
    locationImage: location2,
    locationHeading: "Sector 66 Noida, U.P",
    locationPara: "",
  },
  {
    locationImage: location3,
    locationHeading: "Sector 21 Noida, U.P",
    locationPara: "",
  },
  {
    locationImage: location4,
    locationHeading: "Sector 126 Noida, U.P",
    locationPara: "",
  },
  {
    locationImage: location4,
    locationHeading: "Sector 73 Noida, U.P",
    locationPara: "",
  },
];

const Location = () => {
  return (
    <>
      <section className="location section" id="location">
        <div className="PageBlock">
          <div className="verticalLine"></div>
          <div className="Clear"></div>
        </div>
        <div className="sec_intro">
          <h2 className="sec_title" data-aos="zoom-in">
            Location
          </h2>
        </div>
        <div className="main_content">
          {locationData.map((val, index) => {
            return (
              <div className="a-box" key={index}>
                <div className="img-container">
                  <div className="img-inner">
                    <div className="inner-skew">
                      <img src={val.locationImage} alt="location" />
                    </div>
                  </div>
                </div>
                <div className="text-container">
                  <h3
                    data-aos="zoom-in-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="3000"
                  >
                    {val.locationHeading}
                  </h3>
                  <div
                    data-aos="zoom-in-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="3000"
                  >
                    {val.locationPara}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Location;
