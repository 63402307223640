import React, { useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
import { FaEnvelope } from "react-icons/fa";
import swal from 'sweetalert';

import "../styles/contact.css";
import axios from "axios";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleClick = async (e) => {
    e.preventDefault();
    if (!data.name || !data.email || !data.phone) {
      alert("All fields are requied");
      return;
    } else {
      data.phone = +data.phone;
      await axios
        .post("http://localhost:4000/Chicago_homes/form", {
          ...data,
        })
        .then((res) => {
          setData({ name: "", email: "", phone: "" });
          swal("Thanks for enquiry", "We will contact you soon.", "success");
        })
        .catch((err) => {
          swal("Oops!",err.response.data.status,"error");
          console.log(err);
        });
    }
  };

  return (
    <>
      <section className="Sub_header">
        <h1>Contact Us</h1>
      </section>
      <section className="contact_us">
        <div className="row">
          <div className="content_col">
            <div>
              <AiFillHome className="icon" />
              <span>
                <h5>J Block ,Chhijarsi, Noida Sector 63</h5>
                <p>Noida, Uttar Pradesh 201301</p>
              </span>
            </div>
            <div>
              <AiFillPhone className="icon" />
              <span>
                <h5>+91 8882876687</h5>
                <p>Mon - Sun : Open 24 hrs</p>
              </span>
            </div>
            <div>
              <FaEnvelope className="icon" />
              
              <span>
                <h5 style={{wordBreak: "break-all"}}><a href="mailto:chicagohomes.enquiries@gmail.com">chicagohomes.enquiries@gmail.com</a></h5>
                <p>Email Us Yor Query</p>
              </span>
            </div>
          </div>
          <div className="content_col">
            <form onSubmit={(e) => handleClick(e)}>
              <input
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                type="text"
                placeholder="Enter Name"
                required
              />
              <input
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                type="email"
                placeholder="Enter Email"
                required
              />
              <input
                value={data.phone}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                type="text"
                placeholder="Enter Phone"
                required
              />
              <button type="submit" className="btn">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
