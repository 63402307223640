import React from "react";
import AboutImg from "../assets/images/about.webp";
import "../styles/about.css";

const About = () => {
  return (
    <>
      <section className="about_us container section" id="about">
        <div className="PageBlock">
          <div className="verticalLine"></div>
          <div className="Clear"></div>
        </div>
        <div className="sec_intro">
          <h2 className="sec_title" data-aos="zoom-in">
            About Us
          </h2>
          {/* <p data-aos="zoom-in" data-aos-duration="2500">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima,
            quasi!
          </p> */}
        </div>
        <div className="row">
          <div className="about-col">
            <h1 data-aos="zoom-in" data-aos-duration="2000">
              We provide best services and rooms
            </h1>
            <p data-aos="zoom-in" data-aos-duration="2500">
              At Chicago homes, we draw every diverse situation to rewrite the
              Indian rental homes narrative. We operate by the tenets of FAITH
              and RELIANCE, which require us to put our client's needs before
              our financial gain. We founded this company because we believe
              everyone deserves a loving, tender, and secure environment at
              their residence, which felt like home. Chicago Homes is one of the
              fastest growing and most respected commercial rental homes firm in
              India. Our talented and experienced team provides a wide range of
              services, from hostel to living space and rental flats.
            </p>
          </div>
          <div className="about-col">
            <img
              src={AboutImg}
              alt=""
              className="rooms_col"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="3000"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
