import React  from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <>
      <section className="Sub_header">
        <h1>Forgot Password</h1>
      </section>
      <section className="form login">
        {/* form-container start */}
        <div className="form-content">
          <h2 className="heading">Forgot Password</h2>
          <form action="#">
            {/* Password Filed */}
            <div className="field input-field">
              <input
                type="email"
                placeholder="Enter your Email"
                className="input"
                required
              />
            </div>
            {/* Forgot Password */}
            <div className="form-link">
              <Link to="/login" className="link">
                Go Back
              </Link>
            </div>
            {/* Login Button */}
            <div className="field button-field">
              <button>Reset Password</button>
            </div>
          </form>
        </div>
        {/* form-container close */}
      </section>
    </>
  );
};

export default ForgotPassword;
