import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Services1 from "../assets/images/business.png";
import Services2 from "../assets/images/services2.webp";
import Services3 from "../assets/images/room1.webp";
import Services4 from "../assets/images/services4.webp";
import Services6 from "../assets/images/services6.webp";
import clean from "../assets/images/clean.png";
import couples from "../assets/images/couples.png";
import food from "../assets/images/food.png";
import students from "../assets/images/students.png";
import wifi from "../assets/images/wifi.png";

import "../styles/services.css";
import Aos from "aos";
import "aos/dist/aos.css";

const data = [
  {
    imgUrl: Services1,
    heading: "Paying Guest Accommodation For Corporates",
    subheading:
      "Our accommodation expertise lies in rendering paying guest facility for employees of corporate sector.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: Services2,
    heading: "Paying Guest Accommodations",
    subheading:
      "Get desirable paying guest accommodation as per your needs and requirements to notch the home sickness.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: Services3,
    heading: "Fully Furnished Rooms",
    subheading:
      "We serve our client's diverse requirement of optimum fully furnished Rooms.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: Services4,
    heading: "Hot/Cold Shower",
    subheading:
      "You can adjust the hot or cold water temperatures in the bathroom as per your preference.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: Services6,
    heading: "Wardrobe With Locker",
    subheading:
      "You can secure your personal stuff in your personal wardrobe by locking it.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: clean,
    heading: "Cleaning services",
    subheading:
      "Our professional team cleans shared spaces, including kitchens, living rooms, and shared bathrooms on a regular basis.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: couples,
    heading: "Co-Living",
    subheading:
      "An affordable and flexible co-living apartment that offers space of interaction and socialization without losing the privacy of each family.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: students,
    heading: "Students",
    subheading:
      "Student Housing aspires to create innovative, diverse, and academically supportive campus living communities that transform student live.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: wifi,
    heading: "Free high-speed WiFi",
    subheading:
      "Our homes are optimized for high-speed, commercial-grade WiFi, included at no additional cost to you.",
    moreData: "Enquire Now",
  },
  {
    imgUrl: food,
    heading: "Food",
    subheading:
      "By providing an impeccable service, quality delicious foods and a passionate team, Impressions deliver a friendly and professional experience .",
    moreData: "Enquire Now",
  },
];

const Services = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="services container section" id="services">
        <div className="PageBlock">
          <div className="verticalLine"></div>
          <div className="Clear"></div>
        </div>
        <div className="sec_container">
          <div className="sec_intro">
            <h2 className="sec_title" data-aos="zoom-in">
              Services
            </h2>
            {/* <p data-aos="zoom-in" data-aos-duration="2500">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima,
              quasi!
            </p> */}
          </div>
          <div className="main_container grid">
            {data.map((item) => {
              return (
                <div
                  className="services_post grid"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="3000"
                >
                  {/* service image */}
                  <div className="img_div">
                    <img src={item.imgUrl} alt="" />
                  </div>
                  {/* services details */}
                  <div className="services_details">
                    <div className="price">
                      <h3>{item.heading}</h3>
                      <p>{item.subheading}</p>
                      <Link to="/contact" className="link">
                        {item.moreData}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
