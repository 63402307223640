import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import { BsArrowUp } from "react-icons/bs";
import "./header.css";
import logo from "../../assets/images/logo.png";

const Header = () => {
  const [mobile, setMobile] = useState("nav_bar");
  const headerRef = useRef(null);
  const headerColorRef = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const location = useLocation();
  const pathname = location?.pathname;
  const about = pathname?.substring(1);
  const [toshow, setToShow] = useState(false);
  const showNavbar = () => {
    setMobile("nav_bar active_navbar");
  };
  const hideNavbar = () => {
    setMobile("nav_bar");
  };
  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky_header");
        headerColorRef.current.classList.add("sticky_header_color");
      } else {
        headerRef.current.classList.remove("sticky_header");
        headerColorRef.current.classList.remove("sticky_header_color");
      }
    });
  };
  useEffect(() => {
    stickyHeaderFunc();
    if (
      about === "contact" ||
      about === "forgot-password" ||
      about === "login"
    ) {
      setToShow(false);
    } else setToShow(true);
    return () => window.removeEventListener("scroll", stickyHeaderFunc);
  }, [toshow, about]);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <>
      {/* Go to top button */}
      {showButton && (
        <div className="go-top active" onClick={handleClick}>
          <BsArrowUp className="icon" />
        </div>
      )}
      <section className="navbar_section" id="header">
        <div className="header" ref={headerRef}>
          {/* header logo */}
          <div className="logo_div">
            <Link to="/" className="logo">
              <h1 className="flex" ref={headerColorRef}>
                <img  width="50px" height="50px" src={logo} alt="img" />
                Chicago <span style={{marginLeft:"5px",color:"white"}}> Homes</span>
              </h1>
            </Link>
          </div>
          {/* navbar list */}
          <div className={mobile}>
            <ul className="nav_lists flex">
              <li className="nav_item">
                <Link
                  onClick={() => {
                    const section = document.querySelector("#header");
                    section.scrollIntoView({ behavior: "smooth" });
                    hideNavbar();
                  }}
                  className="nav_link"
                  to="/"
                >
                  Home
                </Link>
              </li>
              {toshow && (
                <>
                  <li className="nav_item">
                    <Link
                      onClick={() => {
                        const section = document.querySelector("#about");
                        section.scrollIntoView({ behavior: "smooth" });
                        hideNavbar();
                      }}
                      className="nav_link"
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav_item">
                    <Link
                      onClick={() => {
                        const section = document.querySelector("#rooms");
                        section.scrollIntoView({ behavior: "smooth" });
                        hideNavbar();
                      }}
                      className="nav_link"
                    >
                      Rooms
                    </Link>
                  </li>
                  <li className="nav_item">
                    <Link
                      onClick={() => {
                        const section = document.querySelector("#services");
                        section.scrollIntoView({ behavior: "smooth" });
                        hideNavbar();
                      }}
                      className="nav_link"
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav_item">
                    <Link
                      onClick={() => {
                        const section = document.querySelector("#gallery");
                        section.scrollIntoView({ behavior: "smooth" });
                        hideNavbar();
                      }}
                      className="nav_link"
                    >
                      Gallery
                    </Link>
                  </li>
                  <li className="nav_item">
                    <Link
                      onClick={() => {
                        const section = document.querySelector("#location");
                        section.scrollIntoView({ behavior: "smooth" });
                        hideNavbar();
                      }}
                      className="nav_link"
                    >
                      Location
                    </Link>
                  </li>
                </>
              )}
              <div className="header_btn flex">
                <Link to="/login" onClick={hideNavbar}>
                  <button className="btn login_btn">Login</button>
                </Link>
                <Link to="/contact" onClick={hideNavbar}>
                  <button className="btn">Contact</button>
                </Link>
              </div>
            </ul>
            {/* close navbar button */}
            <div className="close_navbar" onClick={hideNavbar}>
              <AiFillCloseCircle className="icon" />
            </div>
          </div>
          {/* toogle navbar */}
          <div className="toggle_navbar" onClick={showNavbar}>
            <TbGridDots className="icon" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
